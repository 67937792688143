.video-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin: 0;
    padding: 0;
}

.youtube-player-wrapper {
    width: 100%;
    max-width: 800px;
    position: relative;
    padding-top: 56.25%; /* 16:9 Aspect Ratio */
}

.youtube-player-iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}