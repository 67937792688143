.contact-form-container {
    max-width: 700px;
    margin: 1rem;
    padding: 3px 0px 40px 20px;
    background-color: rgb(29, 29, 29);
    border: 0.1px solid #666666;
    border-radius: 0px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .contact-form-container h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #fff;
  }
  .form-group {
    margin-bottom: 20px;
    color:#ab8d8d !important;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    color: #b3b3b3;
  }
 
  
  .form-group input,
  .form-group textarea {
    width: 90%;
    background-color: #cacaca;
    padding: 8px;
    border: 2px solid #ffffff;
    border-radius: 0px;
    font-size: 16px;
  }
  
  .form-group textarea {
    min-height: 100px;
    resize: vertical;
  }
  
  button[type="submit"] {
    width: 65%;
    padding: 10px;
    background-color: #666666;
    color: white;
    border: 1px;
    border-color: #cacaca;
    border-radius: 0px;
    font-size: 16px;
    cursor: pointer;
  }
  
  button[type="submit"]:hover {
    background-color: #525252;
  }
  
  /* Basic form validation styles */
  input:invalid,
  textarea:invalid {
    border-color: #474747;
  }
  
  /* Focus styles */
  input:focus,
  textarea:focus {
    outline: none;
    border-color: #4a90e2;
    box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.2);
  }
  /* Basic form validation styles */
input:invalid,
textarea:invalid {
  border-color: #ffffff;
}

.success-message {
  text-align: center;
  color: #2ecc71;
  margin: 20px 0;
}

.reset-button {
  width: 100%;
  padding: 10px;
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
}

.reset-button:hover {
  background-color: #357abd;
}

/* Focus styles */
input:focus,
textarea:focus {
  outline: none;
  border-color: #4a90e2;
  box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.2);
}