/* ------------------------------CSS STYLES------------------------------ */
/* --------------------------------------------------------------------- */        
       /* Root Variables */
    :root {
        --background-dark: rgb(36, 36, 36) !important;
        --text-color: #fff !important;
    }
    
    /* Body Style ---------------------------------------------------- */
    body {
        background-color: var(--background-dark) !important;
        color: var(--text-color) !important;
        font-family: 'Special Elite', 'American Typewriter','Courier Prime', monospace;
        margin: 0;
        padding: 0;
        line-height: 1.6;
        display: flex;
        justify-content: center;
    }
    .divider {
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        margin: 2rem 0;
        width: 100%;
        padding-bottom: 0.3rem !important;
    }

/* Global link behavior controls */
a, 
a:link,
a:visited,
a:hover,
a:active,
a:focus,
/* Target all elements within Link components */
[href],
[href]:link,
[href]:visited,
[href]:hover,
[href]:active,
[href]:focus {
    text-decoration: none !important;
    -webkit-tap-highlight-color: transparent;
    -webkit-user-drag: none;
    user-select: none;
}

a, a:link,
[href],
[href]:link {
    color: bisque !important;
}

a:visited,
[href]:visited {
    color: rgb(177, 225, 227) !important;
}

a:hover,
[href]:hover {
    color: rgb(245, 255, 98) !important;
}

a:active,
[href]:active {
    color: rgb(245, 105, 63) !important;
}

/* Specifically target next-article if needed */
.next-article a,
.next-article [href] {
    color: inherit;  /* This will inherit from the above rules */
}
/* Make headers with links inherit link colors */
h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
    color: inherit !important;
}
.nav-links a {
    color: inherit !important;  
}
/* Global link behavior controls - refined for menu structure */
a, 
a:link,
a:visited,
a:hover,
a:active,
a:focus,
.dropdown-trigger,
.nav-links Link,
.nav-links a,
.dropdown Link,
.dropdown a,
.burger-menu-mobile Link,
.burger-menu-mobile a,
.menu-item > Link,
.menu-item > a {
    text-decoration: none !important;
    -webkit-tap-highlight-color: transparent;
    -webkit-user-drag: none;
    user-select: none;
}

/* Normal state */
a, a:link,
.dropdown-trigger,
.nav-links Link,
.nav-links a,
.dropdown Link,
.dropdown a,
.burger-menu-mobile Link,
.burger-menu-mobile a,
.menu-item > Link,
.menu-item > a {
    color: bisque !important;
}

/* Visited state */
a:visited,
.nav-links Link:visited,
.nav-links a:visited,
.dropdown Link:visited,
.dropdown a:visited,
.burger-menu-mobile Link:visited,
.burger-menu-mobile a:visited,
.menu-item > Link:visited,
.menu-item > a:visited {
    color: rgb(177, 225, 227) !important;
}

/* Hover state */
a:hover,
.dropdown-trigger:hover,
.nav-links Link:hover,
.nav-links a:hover,
.dropdown Link:hover,
.dropdown a:hover,
.burger-menu-mobile Link:hover,
.burger-menu-mobile a:hover,
.menu-item > Link:hover,
.menu-item > a:hover {
    color: rgb(245, 255, 98) !important;
}

/* Active state */
a:active,
.dropdown-trigger:active,
.nav-links Link:active,
.nav-links a:active,
.dropdown Link:active,
.dropdown a:active,
.burger-menu-mobile Link:active,
.burger-menu-mobile a:active,
.menu-item > Link:active,
.menu-item > a:active {
    color: rgb(245, 105, 63) !important;
}

/* Additional specificity for nested h3 elements */
.menu-item h3 a,
.menu-item h3 Link {
    color: inherit !important;
}

/* Ensure dropdown items are targeted */
.dropdown > a,
.dropdown > Link {
    color: bisque !important;
}

.dropdown > a:visited,
.dropdown > Link:visited {
    color: rgb(177, 225, 227) !important;
}

.dropdown > a:hover,
.dropdown > Link:hover {
    color: rgb(245, 255, 98) !important;
}

.dropdown > a:active,
.dropdown > Link:active {
    color: rgb(245, 105, 63) !important;
}

    
    /* Base Layout ---------------------------------------------------- */
    /* --------------------------------------------------------------------- */
    /* --------------------------------------------------------------------- */
    .page-container {
        width: 100%;
        max-width: 1400px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        background-color: var(--background-dark);
    }
    
    /* Container for main content and sidebar */
    .container {
        display: grid;
        grid-template-columns: 1fr 300px;
        gap: 2rem;
        max-width: 1400px;
        margin: 0 auto;
        padding: 1rem;
    }
    .subcontainer {
        display: block;
        gap: 2rem;
        max-width: 800px;
        margin: 0 auto;
        padding-left: 1rem;
    }
    .main-content {
        font-size: clamp(1rem, 1vw, 1.2rem);
        color: #fff;
        padding-left: 50px;
        padding-top: 0rem;
        display: flex;
        flex-direction: column;
        align-items: center;
    }  
    /* Reset alignment for text content */
.main-content > p,
.main-content > h1,
.main-content > h2,
.main-content > h3,
.main-content > h4,
.main-content > h5,
.main-content > h6,
.main-content > blockquote,
.main-content > div:not(.image-container):not(.clickable-container) {
    width: 100%;
    align-self: flex-start;
} 
   /* Video Container Styles */
.main-content .video-container {
    width: 100%;
    max-width: 800px;
    margin: 1rem auto 2rem auto;
    padding: 0.75rem;
    border: 1px solid #444;
    box-sizing: border-box;
}

/* YouTube Player Wrapper */
.video-container .youtube-player-wrapper {
    width: 100%;
    max-width: 100%;
}

.video-container iframe {
    width: 100%;
    aspect-ratio: 16/9;
    border: none;
}

/* Responsive adjustments */
@media screen and (max-width: 1024px) {
    .main-content .video-container {
        max-width: calc(100% - 2rem);
    }
}

@media screen and (max-width: 768px) {
    .main-content .video-container {
        margin: 1rem 0;
    }
}
    
    /* Adjust image container padding */

    .image-caption {
        text-align: center !important;
        color: #cccccc;
        font-style: italic;
        margin: 0.3rem;
        font-size: 0.7em;
        width: 100%;
        padding-top: .2rem;
    }

    
    .responsive-image {
        display: block;
        height: auto;
        object-fit: contain;
    }
    
    /* Size variants */
    .img-small {
        max-width: 200px;
        width: 200px;
    }
    
    .img-medium {
        max-width: 400px;
        width: 400px;
    }
    
    .img-large {
        max-width: 600px;
        width: 600px;
    }
    
    /* Or use a custom width with inline style */

    .image-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 auto 1rem auto;
        width: fit-content;
        border: 1px solid #444;
    }
    
    /* Image Container Styles */









/* Container alignment within main content */
.main-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Reset alignment for text content */
.main-content > p,
.main-content > h1,
.main-content > h2,
.main-content > h3,
.main-content > h4,
.main-content > h5,
.main-content > h6,
.main-content > blockquote,
.main-content > div:not(.image-container):not(.clickable-container) {
    width: 100%;
    align-self: flex-start;
}
    /* Ensure paragraphs inside image containers don't add extra space */
    .image-container p {
        margin: 0.5rem 0;
    }
   /* src/comp-styles/gallery-styles.css */

/* For clickable containers */
.clickable-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    width: fit-content;
    max-width: 100%;
    border: 1px solid #444;
    padding: .75rem;
    margin: 1rem;
    transition: border-color 0.2s ease;
}
@media (max-width: 768px) {
    .clickable-container {
        width: 100%;
    }
}
.clickable-container img {
    width: auto;
    height: auto;
    max-width: 100%;
    border: 1px solid #444;
}
  .external-webpage-container {
    margin-bottom: 2rem !important;
    background-color: rgb(233, 233, 233);
  }
 
  
  .clickable-container:hover {
    border-color: #666;
}

    .partingshot {
        width: 800px !important;
        height: auto !important;
        /* Center the image and ensure it doesn't exceed the container */
        max-width: 100%;
      }  

      
     /* Typography ---------------------------------------------------------- */
     h1, h2, h5 {
        font-family: 'Times New Roman', serif;
        color: #fff;
        
    }
    
    h1 {
        font-size: 2.5em !important;
        margin-top: 0.5em;
        margin-bottom: 0.9 !important;
        line-height: 1.2;
        padding-bottom: 1.5rem;
    }
    
    h2 {
        font-size: 2em !important; 
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        padding-bottom: .5rem !important;
        margin-bottom: 1rem !important;
        padding-top: 0.5rem;
    }
    
    h3 {
        font-family: 'Special Elite', 'American Typewriter','Courier Prime', monospace;
        margin-top: .7em !important;
        margin-bottom: 0rem !important;
        color: #fff;
        text-align: center;
        text-decoration: none;
        text-decoration-color: black;
    
    }
    h4 {
        font-family: 'Special Elite', 'American Typewriter','Courier Prime';
        font-size: .8em;
        margin-top: 1em;
        color: #fff;
        text-align: right;
        font-weight: normal;
        padding-bottom: .75rem !important;
        padding-top: .75rem;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        border-top: 1px solid rgba(255, 255, 255, 0.2);
        font-style: italic;
    }
    h5 {
        font-size: 2.5em !important;
        margin-top: 0.3rem;
        margin-bottom: 01rem !important;
        line-height: 1.2;
        padding-bottom: .5rem !important;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);

    }
    h6 {
        font-family: 'Special Elite', 'American Typewriter','Courier Prime', monospace;
        margin-top: 1rem !important;
        margin-bottom: .5rem !important;
        color: #fff;
    }

    
    p {
        text-align: left !important;
        margin: 1.5rem 0 1rem 0 !important;
    } 
    
    
    /* Lists -----------------------------*/
    ul {
        list-style-type: none;
        padding-left: 0;
    }
    
    ul li {
        margin-top: 1em;
        margin-bottom: 1em;
        padding-left: 1.5em;
        position: relative;
    }
    
    ul li:before {
        content: "•";
        position: absolute;
        left: 0;
    }
      /* Images  ######################################################### */
     
    
   



/* ----------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------- */
/* END of Base Styles ---------------------------------------------------------------- */
/* Thumbnail Navigation ---------------------*/
#thumbnail-nav {
    width: 200px;
    background-color: var(--background-dark);
    margin-left: 5rem;
    padding: 0rem; /* Fixed padding */
    height: calc(100vh - 2rem); /* Full height minus padding */
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center content horizontally */
    justify-content: flex-start; /* Align content to start with padding */
    position: sticky; /* Make it sticky */ 
        top: 7rem; /* Distance from the top of the viewport */
    }
    @media screen and (max-width: 768px) {
        #thumbnail-nav {
            width: 100% !important;
            margin: 2rem 0 !important;
            padding: 0 !important;
            position: static !important;
          
        }
        
        .container {
            display: block !important;
        }
    }
    .container #thumbnail-nav {
        width: 200px;
        background-color: var(--background-dark);
        margin-left: 5rem;
        padding: 0rem;
        height: calc(100vh - 2rem);
        overflow-y: auto;
        display: flex;
        
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        position: sticky;
        top: 7rem;
        
    }
    
    /* Hide scrollbar for Chrome, Safari, and Opera */
    .container #thumbnail-nav::-webkit-scrollbar {
        display: none;
    }
    
    /* Hide scrollbar for Firefox */
    .container #thumbnail-nav {
        scrollbar-width: none;
    }
    
    /* Hide scrollbar for IE and Edge */
    .container #thumbnail-nav {
        -ms-overflow-style: none;
    }



/* TABLET adjustments  iPAD Pro ------------------------------------------------------ */
/* ----------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------- */
@media screen and (max-width: 1024px) {

    /* Main Content -------------------------------------------------------- */
    .main-content {
       font-size: clamp(1rem, 1vw, 1.2rem);
       color: #fff;
       padding-left: 50px;
       padding-top: 1rem;
   }
   .container {
    display: grid;
    grid-template-columns: 1fr 300px;
    gap: 2rem;
    max-width: 900px;
    margin: 0 auto;
    padding: 1rem;
} 
} 
   
   
     /* Bottom Navigation Elements ---------------------- */
     .next-article {
       margin-top: 3rem;
       padding-top: 1rem;
       border-top: 1px solid rgba(255, 255, 255, 0.2);
       text-align: right;
   }
   
   .next-article a {
       color: var(--text-color);
       text-decoration: none;
       font-size: 1.2rem;
   }
   /* -------------------------------------------------------------------------- */
   /* -------------------------------------------------------------------------- */
   /* End of iPad  Styles ----------------------------------------------------- */



/* Mobile Adjustments */
@media (max-width: 768px) {
    /* Add your mobile-specific styles here */
}




    
    /* -------------------------------------------------------------------------- */
    /* Media Query for Mobile Phone  (Pixel 7) ------------------------------------- */
    /* -------------------------------------------------------------------------- */
    @media screen and (max-width: 600px) {
        .container {
            grid-template-columns: 1fr;
            padding: 1rem;
        }
        
        #thumbnail-nav {
            width: 100%;
            position: static; /* Disable sticky on small screens */
            margin-top: 2rem;
            text-align: center;
        }
        
        .article-image {
            width: 100% !important;
        }
        
        .main-content {
            padding: 1rem;
        }
        
        h1 {
            font-size: 1.8em;
        }
        
        h2 {
            font-size: 1.4em;
        }
/*--------------------------------Projects Page Media Query------------*>
  /* Tablet (iPad Pro and similar) */
@media screen and (max-width: 1024px) {
    .subcontainer {
        max-width: 90%;
        padding: 0 1rem;
    }

    .partingshot {
        width: 100% !important; /* Override the 800px fixed width */
    }

    .external-webpage-container iframe {
        width: 100%;
        height: 600px; /* Adjusted height for tablet */
    }
}

/* Mobile Devices */
@media screen and (max-width: 768px) {
    .subcontainer {
        max-width: 100%;
        padding: 0 1rem;
    }

    .partingshot {
        width: 100% !important;
    }

    /* Adjust text sizes for readability */
    .subcontainer h1 {
        font-size: 1.8em !important;
    }

    .subcontainer h2 {
        font-size: 1.5em !important;
        padding-bottom: 0.5rem !important;
    }

    /* Handle embedded content */
    .external-webpage-container iframe {
        height: 400px; /* Shorter height for mobile */
    }

    /* Adjust video container */
    .video-container {
        margin: 1rem 0;
    }

    /* Image captions */
    .image-caption {
        font-size: 0.8em;
        margin: 0.5rem;
    }

    /* Adjust paragraph spacing */
    .subcontainer p {
        margin: 1rem 0 !important;
    }
}

/* Small Mobile Devices */
@media screen and (max-width: 480px) {
    .subcontainer {
        padding: 0 0.5rem;
    }

    .external-webpage-container iframe {
        height: 300px; /* Even shorter for small devices */
    }

    /* Further reduce text sizes if needed */
    .subcontainer h1 {
        font-size: 1.5em !important;
    }

    .subcontainer h2 {
        font-size: 1.3em !important;
    }

    .subcontainer p {
        font-size: 0.9em;
    }
}

          
        }