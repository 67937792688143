/* Combined Navigation Styles (menubar-burger.css) */

/* styles.css */
:root {
    --background-dark: rgb(36, 36, 36);
    --text-color: #fff;
}

/* Import necessary global variables and fonts */
.bm-menu {
    font-family: 'Special Elite', 'American Typewriter','Courier Prime', monospace;
    background-color: var(--background-dark);
    color: var(--text-color);
}

/* Style menu items to match your site theme */
.bm-item {
    display: inline-block;
    color: var(--text-color) !important; /* Override default link color */
    text-decoration: none;
    padding: 0.5rem;
    font-family: 'Special Elite', 'American Typewriter','Courier Prime', monospace;
    font-size: 1.1rem;
    border: 1px solid #333;
    margin-bottom: 0.5rem;
    width: 100%;
    transition: border-color 0.2s ease;
}

.bm-item:hover {
    border-color: #666;
    background-color: rgba(255, 255, 255, 0.1);
}

/* Style dropdown items */
.bm-item .dropdown {
    margin-left: 1rem;
    margin-top: 0.5rem;
}

.bm-item .dropdown a {
    color: var(--text-color) !important;
    text-decoration: none;
    padding: 0.5rem;
    display: block;
    border: 1px solid transparent;
}

.bm-item .dropdown a:hover {
    border-color: #666;
    background-color: rgba(255, 255, 255, 0.1);
}

/* Rest of your burger menu styles... */

/* Base Navigation Bar Styles */
.topnav {
    background-color: rgba(0, 0, 0, 0.7);
    padding: 1rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 100;
}

/* Logo */
.logo {
    width: 400px !important;
    height: auto;
    border: 1px solid #444;
    margin-right: 3rem;
    margin-left: 1rem;
}

.logo:hover {
    border-color: #666;
}

/* Main Navigation Links */
.nav-links {
    display: flex;
    gap: 1rem;
    align-items: center;
}

/* Menu Items */




.menu-item {
    position: relative;
}

.menu-item h3 {
    margin: 0;
    padding: 0.5rem .5rem;
    border: 1px solid #333;
    transition: border-color 0.2s ease;
    font-family: 'Special Elite', 'American Typewriter', 'Courier Prime', monospace;
    font-size: 1.1rem;
    text-align: center;
    text-decoration: none;
}

.menu-item.menu-item:hover h3 {
    border-color: #666;
    background-color: rgba(255, 255, 255, 0.15);
    
}

/* Dropdown Menu */
.nav-links .menu-item .dropdown {
    display: none !important;
    position: absolute !important;
    top: 100% !important;
    left: 0;
    min-width: 200px;
    background-color: rgba(0, 0, 0, 0.7);
    border: 1px solid #333;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

.nav-links .menu-item:hover .dropdown {
    display: block !important;
}

/* Dropdown Links */
.nav-links .menu-item .dropdown a {
    display: block !important;
    padding: 0.75rem 1rem;
    color: white !important;
    text-decoration: none !important;
    border: 1px solid transparent;
    transition: all 0.2s ease;
    font-family: 'Special Elite', 'American Typewriter', 'Courier Prime', monospace;
    font-size: 1rem;
}

.nav-links .menu-item .dropdown a:hover {
    background-color: rgba(255, 255, 255, 0.15);
    border-color: #666;
}

/* Hamburger Menu Base Styles */
.hamburger {
    display: none;
    cursor: pointer;
    font-size: 2rem;
    margin-right: 0rem;
}

/* Burger Menu Styles 
.bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    right: 36px;
    top: 36px;
}
(from react-burger-menu) */
.bm-menu .menu-item .dropdown-trigger {
    color: var(--text-color) !important; /* Use your theme color */
    text-decoration: none !important;  /* Remove underline */
    display: block;
    padding: 0.5rem;
    font-family: 'Special Elite', 'American Typewriter','Courier Prime', monospace;
    font-size: 1.1rem;
     /* Style the link containing the dropdown trigger */
}
.bm-menu .menu-item a {
    color: var(--text-color) !important;
    text-decoration: none !important;
}
/* Hover state */
.bm-menu .menu-item a:hover .dropdown-trigger {
    color: #666 !important; /* Or whatever hover color you prefer */
}
.bm-burger-bars {
    background: #373a47;
}

.bm-burger-bars-hover {
    background: #ffffff;
}

.bm-cross-button {
    height: 24px;
    width: 24px;
}

.bm-cross {
    background: #bdc3c7;
}

.bm-menu-wrap {
    position: fixed;
    height: 100%;
}

.bm-menu {
    background: rgba(0, 0, 0, 0.7);
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
}

.bm-morph-shape {
    fill: #373a47;
}

.bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
}

.bm-item {
    display: inline-block;
}

.bm-overlay {
    background: none !important;
}
.bm-menu .menu-item {
    display: block;  /* Change from inline-block to block */
    width: 100%;
}
.bm-menu .dropdown {
    display: block !important;  /* Override any existing display:flex */
    position: static !important;  /* Override absolute positioning */
    width: 100% !important;
    background: none;  /* Remove background */
    border: none;
    box-shadow: none;
    margin-left: 1rem;  /* Indent submenu items */
}
/* Style the dropdown links */
.bm-menu .dropdown a {
    display: block !important;
    width: 100%;
    padding: 0.5rem 1rem;
    margin-bottom: 0.5rem;
    white-space: nowrap;  /* Prevent text wrapping */
}

/* Tablet Adjustments (iPad Pro) */
@media screen and (max-width: 1024px) {
    .nav-links {
        gap: .2rem;
    }
    
    .dropdown {
        display: flex;
        width: 100%;
        box-shadow: none;
    }
    
    .menu-item {
        width: 80%;
    }
    
    .logo {
        width: 300px !important;
        height: auto;
        margin-right: .5rem !important;
        margin-left: 0rem;
    }
    
    .hamburger {
        display: block;
        font-size: 2rem;
        padding-left: .5rem;
    }
}

/* Mobile Adjustments */
@media (max-width: 768px) {
    .nav-links {
        display: none;
    }

    .hamburger {
        display: block;
    }

    /* Mobile Burger Menu Specific Styles */
    .burger-menu-mobile {
        display: block;
    }

    .burger-menu-mobile .bm-menu {
        padding-top: 2.5em;
    }

    .burger-menu-mobile .menu-item {
        color: white;
        padding: 0.8em;
        text-decoration: none;
    }

    .burger-menu-mobile .dropdown {
        padding-left: 1em;
    }

    .burger-menu-mobile .dropdown a {
        display: block;
        padding: 0.5em;
        color: white;
        text-decoration: none;
    }
}

/* Mobile Phone Adjustments (Pixel 7) */
@media screen and (max-width: 600px) {
    .logo {
        width: 200px !important;
        margin-right: 1rem !important;
        margin-left: 1rem;
    }
    
    .hamburger {
        font-size: 2.5rem;
        padding-right: 1rem;
    }
  
  
}
/* Base link states for all menu types */
.nav-links a,
.nav-links Link,
.menu-item a,
.menu-item Link,
.dropdown a,
.dropdown Link,
.bm-menu a,
.bm-menu Link,
.dropdown-trigger,
.bm-menu .dropdown-trigger {
    text-decoration: none !important;
    color: bisque !important;
    transition: color 0.2s ease;
}

/* Visited states */
.nav-links a:visited,
.nav-links Link:visited,
.menu-item a:visited,
.menu-item Link:visited,
.dropdown a:visited,
.dropdown Link:visited,
.bm-menu a:visited,
.bm-menu Link:visited,
.dropdown-trigger:visited,
.bm-menu .dropdown-trigger:visited {
    color: rgb(177, 225, 227) !important;
}

/* Hover states */
.nav-links a:hover,
.nav-links Link:hover,
.menu-item a:hover,
.menu-item Link:hover,
.dropdown a:hover,
.dropdown Link:hover,
.bm-menu a:hover,
.bm-menu Link:hover,
.dropdown-trigger:hover,
.bm-menu .dropdown-trigger:hover {
    color: rgb(245, 255, 98) !important;
}

/* Active states */
.nav-links a:active,
.nav-links Link:active,
.menu-item a:active,
.menu-item Link:active,
.dropdown a:active,
.dropdown Link:active,
.bm-menu a:active,
.bm-menu Link:active,
.dropdown-trigger:active,
.bm-menu .dropdown-trigger:active {
    color: rgb(245, 105, 63) !important;
}

/* Additional specificity for hamburger menu */
.bm-menu .menu-item .dropdown-trigger,
.bm-menu .menu-item > .dropdown-trigger {
    color: bisque !important;
}

.bm-menu .menu-item .dropdown-trigger:hover,
.bm-menu .menu-item > .dropdown-trigger:hover {
    color: rgb(245, 255, 98) !important;
}

/* Force dropdown links to maintain colors */
.nav-links .dropdown a,
.bm-menu .dropdown a {
    color: bisque !important;
}

.nav-links .dropdown a:hover,
.bm-menu .dropdown a:hover {
    color: rgb(245, 255, 98) !important;
}
/* Style the menu item container */
.menu-item {
    position: relative;
}

/* Style the link and maintain border */
.menu-item > Link,
.menu-item > a {
    display: block;
    color: bisque !important;
    text-decoration: none;
    transition: color 0.2s ease;
}

/* Style the h3 with the border */
.menu-item h3 {
    margin: 0;
    padding: 0.5rem .5rem;
    border: 1px solid #333;
    transition: border-color 0.2s ease;
    font-family: 'Special Elite', 'American Typewriter', 'Courier Prime', monospace;
    font-size: 1.1rem;
    text-align: center;
    text-decoration: none;
    color: inherit; /* This will make it inherit the link color */
}

/* Hover states */
.menu-item > Link:hover h3,
.menu-item > a:hover h3 {
    border-color: #666;
    background-color: rgba(255, 255, 255, 0.15);
    color: rgb(245, 255, 98) !important;
}

/* Visited states */
.menu-item > Link:visited h3,
.menu-item > a:visited h3 {
    color: rgb(177, 225, 227) !important;
}

/* Active states */
.menu-item > Link:active h3,
.menu-item > a:active h3 {
    color: rgb(245, 105, 63) !important;
}