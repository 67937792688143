/*--------------------------GALLERY CSS-------------------------*/

/* styles.css */
:root {
    --background-dark: rgb(36, 36, 36);
    --text-color: #fff;

}
    
/* In gallery-styles.css */

.gallery-container {
    width: 100%;        /* Takes full width of parent by default */
    max-width: 800px;   /* Or whatever maximum size you want */
    margin: 0 auto;     /* Centers the container */
    height: auto;       /* Allows height to adjust based on content */
    position: relative; /* For proper child element positioning */
    border: 1px solid #444;
  }
  
  .grid-gallery-container {
    width: 100%;        /* Takes full width of gallery-container */
    height: 100%;
    padding: 1rem;
  }
  
  .grid-gallery {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(min(200px, 100%), 1fr));
    /* minmax(min(100px, 100%), 1fr) ensures thumbnails don't get bigger than container */
    gap: .5rem;
    width: 100%;
  }
  
  .grid-item {
    position: relative;
    width: 100%;
    aspect-ratio: 1;    /* Keeps items square */
    overflow: hidden;
    border: 1px solid #444;
    
  }
  
 .grid-item:hover {
  border-color: #666;

 }

.grid-thumbnail {
    width: 100%;
    height: 100%;
    object-fit: cover; /* This will ensure images cover the area without distortion */
    display: block;
    transition: transform 0.3s ease;
}

.grid-caption {
    display: none;
    text-align: center;
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 0.25rem 0.25rem; /* Reduced padding */
    transform: translateY(100%);
    transition: transform 0.3s ease;
    font-size: 0.8rem; /* Slightly smaller font */
    white-space: nowrap; /* Keeps text on one line */
    overflow: hidden;
    text-overflow: ellipsis; /* Adds ... when text overflows */
}



.grid-item:hover .grid-caption {
    transform: translateY(0);
}

.grid-item:hover .grid-thumbnail {
    transform: scale(1.0);
}

/* Lightbox styles */
.yarl__slide img {
  object-fit: contain !important;
}

.yarl__slide_image {
  width: auto !important;
  height: auto !important;
  object-fit: contain !important;
}
  
.yarl__title {
  text-align: center !important;
  width: 100% !important;
}

.yarl_thumbnails {
  width: 50px;
}

.yarl__thumbnails_thumbnail {
  width: 25px;
  height: 25px;
}
.yarl__thumbnails_thumbnail img {
  object-fit: contain !important;
 
}

.yarl__slide--fullscreen .yarl__slide_image {
  max-width: 100vw !important;
  max-height: 100vh !important;
  width: auto !important;
  height: auto !important;
}

.yarl__slide {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.yarl_slide_caption {
  display: none;
}



/* Tablet-specific thumbnail adjustments */
@media (min-width: 601px) and (max-width: 1024px) {
  .yarl__thumbnails_container {
    padding: 8px !important;
    height: 100px !important;
  }

  .yarl__thumbnails_track {
    gap: 8px !important;
    padding: 0 !important;
  }

  .yarl__thumbnails_thumbnail {
    width: 80px !important;
    height: 80px !important;
    margin: 0 !important;
    padding: 4px !important;
  }

  .yarl__thumbnails_thumbnail img {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover !important;
  }
}

/* Ensure these styles override any defaults */
.yarl__thumbnails_thumbnail[style] {
  margin: 0 !important;
}


/* Mobile Adjustments */



/* Base gallery styles */
.grid-gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 1rem;
}

/* Mobile styles */
@media (max-width: 600px) {
  .grid-gallery {
    grid-template-columns: repeat(auto-fill, minmax(min(80px, 100%), 1fr));
    gap: 0.5rem;
  }

  /* Target lightbox thumbnails specifically */
  .yarl__thumbnails_container,
  .yarl__thumbnails_track,
  .yarl__thumbnails_thumbnails,
  .yarl__thumbnails_thumbnail {
    display: none !important;
    visibility: hidden !important;
    width: 0 !important;
    height: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
    opacity: 0 !important;
  }
}

/* Landscape mode specific styles */
@media (max-width: 900px) and (orientation: landscape) {
  /* Hide thumbnails completely in landscape */
  .yarl__thumbnails_container,
  .yarl__thumbnails_track,
  .yarl__thumbnails_thumbnails,
  .yarl__thumbnails_thumbnail,
  .yarl__thumbnails {
    display: none !important;
    visibility: hidden !important;
    width: 0 !important;
    height: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
    opacity: 0 !important;
    pointer-events: none !important;
  }

  /* Ensure main image takes full height */
  .yarl__slide_image {
    max-height: 100vh !important;
    object-fit: contain !important;
  }
}

/* Ensure these styles override any library defaults */
.yarl__thumbnails_container[style],
.yarl__thumbnails_track[style],
.yarl__thumbnails_thumbnails[style],
.yarl__thumbnails_thumbnail[style] {
  display: none !important;
  visibility: hidden !important;
}